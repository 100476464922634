import { jwtDecode } from "jwt-decode";
import moment from "moment-timezone";

const { NODE_ENV } = process.env;

export const ImageUrl = (image) => {
  let { PUBLIC_URL } = process.env;
  return `${PUBLIC_URL}/images/${image}`;
};

export const formatDateTime = (date, format) => {
  // const date = moment(dateTimeString);
  // const timeString = date.utc().format("HH:mm:ss");
  // const dateString = date.utc().format("YYYY-MM-DD");
  // return `${timeString} ${dateString}`;
  // render: (item) => <span>{}</span>,
  if (!date) return "";
  // return moment(date).utc().subtract(6, 'hour').format(format || "DD MMM, YYYY hh:mm A")
  return moment(date).tz('America/Chicago').format(format || "DD MMM, YYYY hh:mm A")
};

export function createQueryString(params) {
  try {
    return new URLSearchParams(
      Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== null && v !== ''))
    ).toString();
  } catch (error) {
    console.log(error)
  }
}

export const isTokenExpired = (token) => {
  if (NODE_ENV === "development") return false; // Disable token expiry check in development
  if (!token) return true; // No token means it's expired or invalid

  try {
    const decoded = jwtDecode(token); // Decode the token
    if (!decoded.exp) {
      return true; // If the token has no `exp` field, consider it expired
    }
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decoded.exp <= currentTime; // Token is expired if `exp` <= `currentTime`
  } catch (error) {
    console.error("Error decoding token:", error);
    return true; // If decoding fails, treat it as expired
  }
};