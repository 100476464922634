import { FaCheckCircle } from "react-icons/fa";
import swal from "sweetalert";
import { useGetSettingQuery, useUpdateSettingMutation } from "../../api/setting";

const paymentMethods = [
  { id: 1, name: "paypal" },
  { id: 2, name: "stripe" },
];

const categories = [
  { id: 1, name: "Payment" },
];

export default function SettingsPage() {

  const {data , refetch} =  useGetSettingQuery()
  const [updatePost] = useUpdateSettingMutation();


 async function handleUpdate(methodName){

  if(data?.paymentMethod === methodName) return

  const res = await updatePost({
    paymentMethod : methodName
  })
  if(res?.data?.status){
    swal("Success!", "Method Updated Successfully", "success");
    refetch()
  }else{
    swal("Oops!", res?.message || "something went wrong", "error");
  }
 }

  return (
    <div className="mx-4 max-w-screen-2xl sm:mx-8 xl:mx-5 p-10 w-screen h-screen bg-white text-black">
      <h1 className="border-b py-6 text-4xl font-semibold">Settings</h1>
      <div className="grid grid-cols-8 pt-3 pb-10 sm:grid-cols-10">
        
        {/* Sidebar */}
        <div className="col-span-2 hidden sm:block">
          <ul>
            {categories.map((category) => (
              <li
                key={category.id}
                className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition ${category.id === 1 && "border-l-blue-700 text-blue-700"}`}
              >
                {category.name}
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content */}
        <div className="col-span-8 overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow">
          <div className="pt-4">
            <h1 className="py-2 text-2xl font-semibold">Payment Options</h1>
          </div>
          <hr className="mt-4 mb-8" />

          <div className="space-y-2">
            {paymentMethods.map((method) => (
              <div
                key={method.id}
                className={`cursor-pointer rounded-md border px-6 py-2 text-2xl ${
                  data?.paymentMethod === method.name ? "bg-blue-50" : "bg-white hover:bg-blue-50"
                }`}
                onClick={() => handleUpdate(method.name)}
              >
                <div className="flex justify-between items-center">
                    <p>
                {method.name}
                    </p>
                    {data?.paymentMethod === method.name && <FaCheckCircle className="text-green-400"/>}
                </div>

              </div>
            ))}
          </div>

          <hr className="mt-4 mb-8" />
        </div>

        
      </div>
    </div>
  );
}

// Reusable Member Card Component
// function MemberCard({ name, email, team }) {
//   return (
//     <div className="rounded-md border bg-white">
//       <div className="flex w-full items-center px-6 py-2">
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           className="mr-2 h-5 w-5 text-gray-400"
//           viewBox="0 0 20 20"
//           fill="currentColor"
//         >
//           <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
//         </svg>
//         <span>{name}</span>
//       </div>
//       {email && (
//         <div className="flex flex-col space-y-3 px-4 py-6 sm:px-10">
//           <label className="block">
//             <p className="text-sm">Name</p>
//             <input
//               className="w-full rounded-md border py-2 px-2 bg-gray-50 outline-none ring-blue-600 focus:ring-1"
//               type="text"
//               value={name}
//               readOnly
//             />
//           </label>
//           <label className="block">
//             <p className="text-sm">Email</p>
//             <input
//               className="w-full rounded-md border py-2 px-2 bg-gray-50 outline-none ring-blue-600 focus:ring-1"
//               type="email"
//               value={email}
//               readOnly
//             />
//           </label>
//           <label className="block">
//             <p className="text-sm">Team</p>
//             <select
//               className="w-full rounded-md border py-2 px-2 bg-gray-50 outline-none ring-blue-600 focus:ring-1"
//               value={team}
//               readOnly
//             >
//               <option value="UI/UX Design">UI/UX Design</option>
//               <option value="Marketing">Marketing</option>
//               <option value="Engineering">Engineering</option>
//             </select>
//           </label>
//         </div>
//       )}
//     </div>
//   );
// }
