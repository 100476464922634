import {
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Popover,
  Row,
  Select
} from "antd";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FaFilter, FaSearch } from "react-icons/fa";
import { useGetGraphReportQuery } from "../../api/reports";
ChartJS.register(ArcElement, Tooltip, Legend);

const getDateRange = (filterType) => {
  let startDate, endDate;
  switch (filterType) {
    case 'All':
      startDate = null;
      endDate = null;
      break;

    case 'Today':
      startDate = dayjs().startOf('day');
      endDate = dayjs().endOf('day');
      break;
      case 'Yesterday':
        startDate = dayjs().subtract(1, 'day').startOf('day');
        endDate = dayjs().subtract(1, 'day').endOf('day');
        break;

    case 'This Week':
      startDate = dayjs().startOf('week');
      endDate = dayjs().endOf('week');
      break;

    case 'This Month':
      startDate = dayjs().startOf('month');
      endDate = dayjs().endOf('month');
      break;

    case 'Last Month':
      startDate = dayjs().subtract(1, 'month').startOf('month');
      endDate = dayjs().subtract(1, 'month').endOf('month');
      break;

      
    case 'This Year':
      startDate = dayjs().startOf('year');
      endDate = dayjs().endOf('year');
      break;

    default:
      throw new Error('Invalid filter type');
  }
  return {
    startDate,
    endDate,
  };
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
    },
    tooltip: {
      enabled: true,
    },
  },
};

const coinChartData = {
  labels: ["Gold Coins", "Red Coins"],
  datasets: [
    {
      label: "  Coins Report",
      data: [0, 0],
      backgroundColor: [
        'rgba(255, 206, 86, 0.5)',
        'rgba(255, 99, 132, 0.5)',
      ],
      hoverOffset: 4,
      borderWidth: 1,
    },
  ],
};

const payoutChartData = {
  labels: ["Payout", "Purchasing"],
  datasets: [
    {
      label: "  Payment Report",
      data: [0, 0],
      backgroundColor: [
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
      ],
      hoverOffset: 4,
      borderWidth: 1,
    },
  ],
}

const PieChart = () => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({
    status: null,
    keyword: "",
    startDate: dayjs().startOf('day'),
    endDate: dayjs().endOf('day'),
  });

  const [coinChart, setCoinChart] = useState(structuredClone(coinChartData));
  const [payoutChart, setPayoutChart] = useState(structuredClone(payoutChartData));

  const { data: reportData, refetch } = useGetGraphReportQuery({ ...filter })

  useEffect(() => {
    if (reportData) {
      setCoinChart(prev => {
        return {
          ...prev,
          datasets: [
            {
              ...prev.datasets[0],
              data: [reportData?.goldCoins, reportData?.redCoins]
            }
          ]
        }
      });
      setPayoutChart(prev => {
        return {
          ...prev,
          datasets: [
            {
              ...prev.datasets[0],
              data: [reportData?.payout, reportData?.purchasing]
            }
          ]
        }
      });
    }
  }, [reportData])

  const handleSearch = (value) => {
    setFilter({
      ...filter,
      keyword: value,
    });
  };

  const resetFilter = () => {
    setFilter({
      status: null,
      keyword: "",
      startDate: null,
      endDate: null,
    });
    refetch();
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleFrom = (date) => {
    setFilter({
      ...filter,
      startDate: date,
    });
  };

  const handleTo = (date) => {
    setFilter({
      ...filter,
      endDate: date,
    });
  };


  function handleDateChane(e) {
    let res = getDateRange(e)
    setFilter({
      ...filter,
      startDate: res.startDate,
      endDate: res.endDate,
    });
  }

  const filterContent = (
    <div className="filterDropdown">
      <div>
        <p className="mainLabel" style={{ padding: "10px" }}>
          Filter
        </p>
      </div>
      <hr style={{ margin: 0 }} />

      <div className="filterDropdownBody">
        <p className="mainLabel">Creation Date:</p>
        <DatePicker
          showTime
          format="YYYY-MM-DD HH:mm"
          value={filter?.startDate}
          onChange={(e) => handleFrom(e)}
          placeholder="Select Date and Time"
          style={{width : "100%" ,paddingTop : "10px" ,paddingBottom:"10px"}} 
          className="my-3"

        />
          <DatePicker
          showTime
          format="YYYY-MM-DD HH:mm"
          value={filter.endDate}
          onChange={(e) => handleTo(e)}
          placeholder="Select Date and Time"
          style={{width : "100%" ,paddingTop : "10px" ,paddingBottom:"10px"}} 
          className="my-3"
        />

        <Button
          type="primary"
          shape="round"
          block
          size={"large"}
          style={{ marginBottom: "10px" }}
          className="mainButton primaryButton"
          onClick={() => refetch()}
        >
          Apply
        </Button>
        <Button
          type="primary"
          shape="round"
          block
          size={"large"}
          className="mainButton primaryButton2"
          onClick={() => resetFilter()}
        >
          Clear All
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Layout className="configuration">
        <div className="boxDetails">
          <Row style={{ padding: "10px 20px" }}>
            <Col
              xs={24}
              md={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <h1 className="pageTitle">Graph View</h1>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
            </Col>
          </Row>


          <Row style={{ padding: "10px 20px" }}>
            <Col xs={24} md={12}>

              <Row className="flex items-center gap-7 text-black">

                <div>
                  <h5 style={{ display: "inline", fontSize: 16 }}>Select : </h5>
                  <Select
                    size={"large"}
                    className="chartSelectBox"
                    defaultValue={"Today"}
                    onChange={(e) => handleDateChane(e)}
                    style={{
                      width: 120,
                      textAlign: "left",
                    }}
                    options={[
                      { value: "Today", label: "Today" },
                      { value: "Yesterday", label: "Yesterday" },
                      { value: "This Week", label: "This Week" },
                      { value: "This Month", label: "This Month" },
                      { value: "Last Month", label: "Last Month" },
                      { value: "This Year", label: "This Year" },
                    ]}
                  />
                  &emsp;
                  <h5 style={{ display: "inline", fontSize: 16 }}>Date</h5>
                </div>
              </Row>
            </Col>


            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Input
                style={{ width: "250px" }}
                className="mainInput dashInput"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                suffix={
                  <FaSearch
                    style={{
                      color: "#3c5a92",
                      fontSize: 16,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      refetch()
                    }
                  />
                }
                onPressEnter={(e) =>
                  refetch()
                }
              />
              &emsp;
              <Popover
                content={filterContent}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                placement="bottomRight"
                arrow={false}
              >
                <Button
                  style={{
                    padding: "10px 15px",
                    height: "auto",
                  }}
                  className="fltr-btn"
                >
                  <FaFilter style={{ fontSize: "16px", color: "white" }} />
                </Button>
              </Popover>
            </Col>
          </Row>

          <div className="flex flex-col px-1 mt-8 lg:flex-row gap-10">
            <div className="w-1/2">
              <div className="flex flex-col gap-3 mb-5 h-[90px]">
                <h2 className="text-black text-xl font-semibold ">Approved Coins Report</h2>
                <p className="text-black">Red Coins: {reportData?.redCoins}</p>
                <p className="text-black">Gold Coins: {reportData?.goldCoins}</p>
              </div>
              <div className="w-[400px h-[400px]" >
                <Doughnut data={coinChart} options={options} />
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex flex-col gap-3 mb-5 h-[90px]">
              <h2 className="text-black text-xl font-semibold ">Payout Report</h2>
                <p className="text-black">Purchasing: {reportData?.purchasing}</p>
                <p className="text-black">Payout: {reportData?.payout}</p>
                <p className="text-black">Profit: {reportData?.purchasing - reportData?.payout}</p>
              </div>
              <div className="w-[400px h-[400px]" >
                <Doughnut data={payoutChart} options={options} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PieChart;
