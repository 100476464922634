import {
  Button,
  Col,
  Layout,
  Row,
  Typography
} from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetPaypalKeysQuery } from "../../api/paypal";
import { useGetStripeKeysQuery } from "../../api/stripe";
import { useGetVivamoKeysQuery } from "../../api/vivamo";

const { Title, Text } = Typography;

function PaypalCredentials() {

  const {data: paypalKeys} = useGetPaypalKeysQuery();
  const {data: stripeKeys} = useGetStripeKeysQuery();
  const {data: vivamoKeys} = useGetVivamoKeysQuery();
  const navigate = useNavigate();

  return (
    <>
      <Layout className="configuration">

        {/* Paypal Credentials */}
        <div className="boxDetails">
          <Row style={{ padding: "10px 20px" }}>
            <Col
              xs={24}
              md={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* <FaArrowLeft
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              /> */}
              &emsp;
              <Title level={1} style={{ margin: 0 }}>
                Payment Credentials
              </Title>
            </Col>
          </Row>
          <br />
          <Row justify="center" className="whitebg" style={{ padding: "20px" }}>
            <Col xs={24} md={24} xl={24}>
              <Row
                justify="space-between"
                gutter={30}
                className="info-area padding-y-40 greybg margin-y-40 border-radius-15"
              >
                <Col xs={24} md={24} lg={12}>
                  <Title level={5} className="product-bottomtitle">
                    Client Id
                  </Title>
                  <Text level={3} className="product-text">
                    {paypalKeys?.clientId ? paypalKeys?.clientId : "-"}
                  </Text>
                  <Title level={5} className="product-bottomtitle">
                    Client Secret
                  </Title>
                  <Text className="product-text">
                    {paypalKeys?.clientSecret
                      ? paypalKeys?.clientSecret
                      : "-"}
                  </Text>
                  <br />

                  <br />
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    style={{ padding: "12px 40px", height: "auto" }}
                    onClick={() =>
                      navigate("/payment-credentials/edit-credentials",{state: {paymentMethod : "paypal"}})
                    }
                  >
                    Edit Credentials
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
        </div>

        {/* stripe Credentials */}
        <div className="boxDetails">
          <Row style={{ padding: "10px 20px" }}>
            <Col
              xs={24}
              md={12}
              style={{ display: "flex", alignItems: "center" }}
            >      
              <Title level={1} style={{ margin: 0 }}>
              Stripe Credentials
              </Title>
            </Col>
          </Row>
          <br />
          <Row justify="center" className="whitebg" style={{ padding: "20px" }}>
            <Col xs={24} md={24} xl={24}>
              <Row
                justify="space-between"
                gutter={30}
                className="info-area padding-y-40 greybg margin-y-40 border-radius-15"
              >
                <Col xs={24} md={24} lg={12}>
                  <Title level={5} className="product-bottomtitle">
                    Client Id
                  </Title>
                  <Text level={3} className="product-text">
                    {stripeKeys?.publishableKey ? stripeKeys?.publishableKey : "-"}
                  </Text>
                  <Title level={5} className="product-bottomtitle">
                    Client Secret
                  </Title>
                  <Text className="product-text">
                    {stripeKeys?.secretKey
                      ? stripeKeys?.secretKey
                      : "-"}
                  </Text>
                  <br />

                  <br />
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    style={{ padding: "12px 40px", height: "auto" }}
                    onClick={() =>
                      navigate("/payment-credentials/edit-credentials" ,{state: {paymentMethod : "stripe"}})
                    }
                  >
                    Edit Credentials
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
        </div>

        {/* Vivamo Credentials */}
        <div className="boxDetails">
          <Row style={{ padding: "10px 20px" }}>
            <Col
              xs={24}
              md={12}
              style={{ display: "flex", alignItems: "center" }}
            >      
              <Title level={1} style={{ margin: 0 }}>
              Vivamo Credentials
              </Title>
            </Col>
          </Row>
          <br />
          <Row justify="center" className="whitebg" style={{ padding: "20px" }}>
            <Col xs={24} md={24} xl={24}>
              <Row
                justify="space-between"
                gutter={30}
                className="info-area padding-y-40 greybg margin-y-40 border-radius-15"
              >
                <Col xs={24} md={24} lg={12}>
                  <Title level={5} className="product-bottomtitle">
                    Client Id
                  </Title>
                  <Text level={3} className="product-text">
                    {vivamoKeys?.publicKey ? vivamoKeys?.publicKey : "-"}
                  </Text>
                  <Title level={5} className="product-bottomtitle">
                    Client Secret
                  </Title>
                  <Text className="product-text">
                    {vivamoKeys?.secretKey
                      ? vivamoKeys?.secretKey
                      : "-"}
                  </Text>
                  <br />

                  <br />
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    style={{ padding: "12px 40px", height: "auto" }}
                    onClick={() =>
                      navigate("/payment-credentials/edit-credentials" ,{state: {paymentMethod : "vivamo"}})
                    }
                  >
                    Edit Credentials
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
        </div>
      </Layout>
    </>
  );
}

export default PaypalCredentials;
