import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import { coinApi } from '../api/coins';
import { orderApi } from '../api/order';
import { paypalApi } from '../api/paypal';
import { stripeApi } from '../api/stripe';
import { reportApi } from "../api/reports";
import { settingApi } from "../api/setting";
import { transactionApi } from '../api/transaction';
import { chatApi } from '../views/chat/chatApiSlice';
import userReducer from './slice/authSlice';
import { jazOrderApi } from '../api/jazOrder';
import { vivamoApi } from '../api/vivamo';

const rootReducer = combineReducers({
  user: userReducer,
  [coinApi.reducerPath]: coinApi.reducer,
  [paypalApi.reducerPath]: paypalApi.reducer,
  [stripeApi.reducerPath]: stripeApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [jazOrderApi.reducerPath]: jazOrderApi.reducer,
  [settingApi.reducerPath]: settingApi.reducer,
  [vivamoApi.reducerPath]: vivamoApi.reducer,
})

// const persistConfig = {
//   key: 'real_money_admin',
//   storage,
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)
const persistedReducer = rootReducer

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(coinApi.middleware).concat(paypalApi.middleware).concat(transactionApi.middleware).concat(orderApi.middleware).concat(chatApi.middleware).concat(reportApi.middleware).concat(jazOrderApi.middleware).concat(stripeApi.middleware).concat(settingApi.middleware).concat(vivamoApi.middleware),
})

export const persistor = persistStore(store)