import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../config/constants';
import { forceRefetch, prepareHeaders } from './coins';


export const vivamoApi = createApi({
    reducerPath: 'vivamoApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL + "/vivamo", prepareHeaders }),
    tagTypes: ['Vivamo'],
    endpoints: (builder) => ({
        getVivamoKeys: builder.query({
            query: () => "/keys",
            transformResponse: (response) => response.data,
            forceRefetch
        }),
    }),
})

export const { useGetVivamoKeysQuery } = vivamoApi
