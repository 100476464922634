import React from 'react';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-purple-100 h-screen flex items-center justify-center">
      <div className="bg-white p-12 rounded-xl shadow-xl text-center max-w-3xl w-full space-y-8">
        {/* SVG illustration */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-32 w-32 text-red-500 animate-pulse"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>

        <h1 className="text-6xl font-extrabold text-red-600 tracking-tight mb-4">403</h1>
        <p className="text-2xl text-gray-700 mb-6">Unauthorized Access</p>
        <p className="text-lg text-gray-500 mb-8">You do not have permission to view this page. Please check your credentials or contact the administrator for assistance.</p>

        <Link
          to="/"
          className="inline-block mt-4 px-8 py-3 bg-gradient-to-r from-[#a7884e] to-[#e2b35a] text-white text-lg font-semibold rounded-lg shadow-xl hover:from-[#e2b35a] hover:to-[#e2b35a] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300"
        >
          Go Back to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default Unauthorized;
