import React, { memo } from "react";
import { FaBell } from "react-icons/fa";
import { useGetMyChatsQuery } from "../../views/chat/chatApiSlice";

function ChatBell() {
  const { data } = useGetMyChatsQuery();
  const unreadMsg = data?.reduce((sum, item) => sum + item?.unreadCount, 0);
  
  return (
    <div className={`${unreadMsg ? "block" : "hidden"}`}>
      <FaBell size={22} className="absolute z-50 top-4 right-2" />
      <div className="w-6 h-6 z-50 absolute top-0 right-3 bg-red-400 rounded-full flex justify-center items-center font-extrabold">
        {unreadMsg}
      </div>
    </div>
  );
}

export default memo(ChatBell);
