"use client";


import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { useSendOtpMutation, useVerifyOtpMutation } from "../../api/otp";
import { useLocation, useNavigate } from "react-router-dom";


export default function VerifyOtp() {

  const [verfyCode, { isLoading }] = useVerifyOtpMutation();
  const [sendOtp] =  useSendOtpMutation()
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);
  const {email} = useSelector((state) => state.user.userData);
  const navigate = useNavigate()
  const location = useLocation();
  const id = location?.state?.id || null;

  const handleChange = (e, index) => {
    const newValues = [...inputValues];
    const value = e.target.value;

    if (/^\d$/.test(value) || value === "") {
      newValues[index] = value;
      setInputValues(newValues);
    }

    if (value.length === 1 && index < 3) {
      inputsRef.current[index + 1]?.focus();
    } else if (value.length === 0 && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValues.includes("") || inputValues.length < 4) {
      swal("Oops","Please fill all fields","error")
      return;
    }

    const res = await verfyCode({ email, code: inputValues.join("") });
    if (res.data?.status) {
      swal("Success",res?.data?.message || "Account verified successfully","success")
      navigate(`/user-management/update-coins/${id}`)
    } else {
     swal("Oops",res?.data?.message || res?.error?.data?.message  ||"something went wrong","error")
    }
  };

  const handlerResend = async () =>{
    const res =  await sendOtp({email})
    if(res?.data?.status){
      swal("Otp sent successfully",res?.data?.message || "Please check your email for OTP","success")
    }else{
      swal("Oops",res?.data?.message || res?.error?.data?.message  ||"something went wrong","error")
  }
  }

  return (
    <div className="flex min-h-screen flex-1  flex-col justify-start items-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      </div>

      <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
        <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
          <div className="flex flex-col items-center justify-center text-center space-y-2">
            <div className="font-semibold text-2xl text-[#e6ab3e] ">
              <p>OTP Verification</p>
            </div>
            <div className="flex  flex-row text-[16px] font-medium text-gray-400">
              <p>
                We have sent a code to your email{" "}
                <span className="text-[#e6ab3e]">{email}</span>
              </p>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-16">
              <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                {inputValues.map((value, index) => (
                  <div key={index} className="w-16 h-16 text-[#efbd61]">
                    <input
                      className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border  text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-[#e6ab3e] border-[#efbd61]"
                      type="number"
                      value={value}
                      onChange={(e) => handleChange(e, index)}
                      ref={(el) => (inputsRef.current[index] = el)}
                      maxLength={1}
                    />
                  </div>
                ))}
              </div>

              <div className="flex flex-col space-y-5">
                <div>
                  <button
                    type="submit"
                    className="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-[#efbd61] hover:bg-[#e6ab3e] border-none text-white font-bold text-sm shadow-sm"
                  >
                    {isLoading ? "loading": "Verify OTP"}
                  </button>
                </div>

                <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                  <p>Didn't receive code?</p>
                  <p
                    className="flex flex-row items-center text-[#efbd61] hover:text-[#e6ab3e cursor-pointer"
                    rel="noopener noreferrer"
                    onClick={handlerResend}
                  >
                    Resend
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

