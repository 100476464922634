import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../config/constants';
import { forceRefetch, prepareHeaders } from './coins';


export const stripeApi = createApi({
    reducerPath: 'stripeApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL + "/stripe", prepareHeaders }),
    tagTypes: ['Stripe'],
    endpoints: (builder) => ({
        getStripeKeys: builder.query({
            query: () => "/keys",
            transformResponse: (response) => response.data,
            forceRefetch
        }),
    }),
})

export const { useGetStripeKeysQuery } = stripeApi
