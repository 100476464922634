import {
  Col,
  Layout,
  Row
} from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Tabs
} from 'antd';
import avatar from "../../assets/avatar.png";
import { UPLOADS_URL2 } from '../../config/constants';


function UserDetails() {
  // const pageNumber = location?.state?.pageNumber || 1;
  // const keyword = location?.state?.keyword;

  const location = useLocation();
  const entries = location?.state?.entries
  const winners = location?.state?.winners
  
  const [activeTab,setActiveTab]= useState("Entries")

  
  const onChange1 = (key) => {
    setActiveTab(key);
  };
  const tabs = [
    {
      key:1,
      label : "Entries",
    },
    {
      key:2,
      label : "winners"
    },
  ]

  return (
    <Layout className="configuration">
      <Tabs
      onChange={onChange1}
      type="card"
      items={tabs.map((tab) => {
        return {
          label: `${tab.label}`,
          key: tab.label,
          // children: `Content of Tab Pane ${tab.key}`,
        };
      })}
    />

      <div className="boxDetails">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <h1 className="pageTitle">{activeTab}</h1>
          </Col>
          <Col
            xs={24}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >

          </Col>
             
      



      <div className={`${activeTab !== "Entries" && "hidden"} flex flex-col gap-8 mt-4 w-full`}>
          {entries?.length > 0 ? 
            entries.map((doc)=>{
            return <Cards data={doc}/>

            })
            :
            <p className='text-center w-full text-black text-2xl'>No User Found</p>
            }
      </div>



      <div className={`${activeTab !== "winners" && "hidden"} flex flex-col gap-8 mt-4 w-full`}>
        {winners?.length > 0 ?
         winners.map((doc)=>{
        return <Cards data={doc}/>
        })
        :
        <p className='text-center w-full text-black text-2xl'>No User Found</p>
        }

      </div>  

        </Row>

      </div>
          </Layout> 
  )
}
export default UserDetails;


const Cards = ({data})=>{

return(
  <article className="rounded-xl border border-gray-700 bg-[#121a1d] p-3 md:p-4">
  <div className="flex items-center gap-4">
    <img
      alt=""
      src={data?.image ? UPLOADS_URL2 + data.image : avatar}
      className="size-16 rounded-full object-cover"
    />
    <div className='flex flex-col items-start'>
      <h3 className="text-lg font-medium text-white order-2">{data?.firstName ? data?.firstName +" "+ data?.lastName  : data?.user?.firstName +" "+ data?.user?.lastName}</h3>

      <div className={`flex items-center gap-3 justify-center ${data?.entryCount ? "order-1 text-xl" : "order-3" } `}>
        {
          data?.entryCount && <p>Entry Count</p>
        }
      <p className="text-sm md:text-xl font-medium text-white">{data?.entryCount ? data?.entryCount : data?.email ? data?.email : "" }</p>
      </div>
    </div>
  </div>
</article>
)
}

