import { jwtDecode } from "jwt-decode";
import React from "react";
import { Navigate } from "react-router";

export const ROLES = {
  user: "USER",
  super_admin: "SUPER_ADMIN",
  admin: "ADMIN",
  sub_admin: "SUB_ADMIN"
}

const UserAuthCheck = ({ roles = [], children }) => {
  const userToken = localStorage.getItem("userToken");
  const { role } = JSON.parse(localStorage.getItem("userData")) ?? {};

  if (roles?.toString()?.toLowerCase() === "all") roles = Object.values(ROLES)

  if (!userToken) {
    return <Navigate to="/signin" replace={true} />;
  }

  try {
    const decodedToken = jwtDecode(userToken);
    const currentTime = Date.now() / 1000; // Current time in seconds
    if (decodedToken.exp < currentTime) {
      // Token has expired
      localStorage.removeItem("userToken");
      localStorage.removeItem("userData");
      return <Navigate to="/signin" replace={true} />;
    }
  } catch (error) {
    // If decoding fails, redirect to signin
    return <Navigate to="/signin" replace={true} />;
  }

  if (!roles.includes(role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <>{children}</>;
};

export default UserAuthCheck;
