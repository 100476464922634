import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../config/constants';
import { forceRefetch, prepareHeaders } from './coins';

export const settingApi = createApi({
    reducerPath: 'settingApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL + "/settings", prepareHeaders }),
    tagTypes: ['setting'],
    endpoints: (builder) => ({
        getSetting: builder.query({
            query: () => "",
            transformResponse: (response) => response.data,
            forceRefetch
        }),

        updateSetting: builder.mutation({
            query: ({ paymentMethod}) => ({
                url: "",
                method: 'PATCH',
                body: {paymentMethod},
            })
        })


    }),
})

export const { useGetSettingQuery , useUpdateSettingMutation } = settingApi
