import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../config/constants';
import { prepareHeaders } from './coins';

export const otpApi = createApi({
    reducerPath: 'otp',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL + "/auth", prepareHeaders }),
    tagTypes: ['Otp'],
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({

    sendOtp: builder.mutation({
            query: ({ email }) => ({
                url: "/send-otp",
                method: 'POST',
                body: {email},
            })
        }),
    verifyOtp: builder.mutation({
            query: ({ email ,code}) => ({
                url: "/verify-otp",
                method: 'POST',
                body: {email , code},
            })
        })

    }),
});

export const { useSendOtpMutation , useVerifyOtpMutation } = otpApi;
