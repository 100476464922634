import {
  Button,
  Col,
  DatePicker,
  Image,
  Input,
  Layout,
  Modal,
  Pagination,
  Popover,
  Row,
  Select,
  Skeleton,
  Table,
  Typography
} from "antd";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { FaFilter, FaSearch } from "react-icons/fa";
import { FcApproval } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { useGetGiveawayReportQuery } from "../../api/reports";
import { UPLOADS_URL } from "../../config/constants";
import { formatDateTime, ImageUrl } from "../../config/functions";
import useDebounce from "../../hooks/useDebounce";
import { useGetAllUserQuery } from "../chat/chatApiSlice";


const getDateRange = (filterType) => {
  let startDate, endDate;
  switch (filterType) {
    case 'All':
      startDate = null;
      endDate = null;
      break;

    case 'Today':
      startDate = dayjs().startOf('day');
      endDate = dayjs().endOf('day');
      break;
      case 'Yesterday':
        startDate = dayjs().subtract(1, 'day').startOf('day');
        endDate = dayjs().subtract(1, 'day').endOf('day');
        break;

    case 'This Week':
      startDate = dayjs().startOf('week');
      endDate = dayjs().endOf('week');
      break;

    case 'This Month':
      startDate = dayjs().startOf('month');
      endDate = dayjs().endOf('month');
      break;

    case 'Last Month':
      startDate = dayjs().subtract(1, 'month').startOf('month');
      endDate = dayjs().subtract(1, 'month').endOf('month');
      break;

      
    case 'This Year':
      startDate = dayjs().startOf('year');
      endDate = dayjs().endOf('year');
      break;

    default:
      throw new Error('Invalid filter type');
  }
  return {
    startDate,
    endDate,
  };
};


function Reports() {
  // const token = useSelector((state) => state.user.userToken);
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [procucts, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [user,setUser]=useState(null)
  const { data: allUsers } = useGetAllUserQuery();
  

  const [filter, setFilter] = useState({
    status: null,
    keyword: "",
    startDate: dayjs().startOf('day'),
    endDate: dayjs().endOf('day'),
  });

  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });


  const { limit, pageNumber: page ,keyword:search} = paginationConfig;
  const debouncedSearch = useDebounce(search, 700);

  

  const { data, isFetching ,refetch}  = useGetGiveawayReportQuery({ page, limit, keyword : debouncedSearch, ...filter,userId:`${user ? user?._id : ""}`})

  const selectedService = null
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = `Showing records ${endIndex} of ${paginationConfig.totalDocs}`;



  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });

    refetch();
  };

  const handleSearch = (value) => {
    setFilter({
      ...filter,
      keyword: value,
    });
  };

  // const handleStatusChange = (value) => {
  //   setFilter({
  //     ...filter,
  //     status: value,
  //   });
  // };

  const resetFilter = () => {
    setFilter({
      status: null,
      keyword: "",
      startDate: null,
      endDate: null,
    });
    refetch();
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleFrom = (date) => {
    setFilter({
      ...filter,
      startDate: date,
    });
  };

  const handleTo = (date) => {
    setFilter({
      ...filter,
      endDate: date,
    });
  };

  const handleLimitChange = (pageSize) => {
    setPaginationConfig({
      ...paginationConfig,
      limit: pageSize,
      current: 1,
    });

    refetch();
  };
  const handleStatus = async () => {
    try {
      const index = procucts.findIndex(
        (user) => user._id === selectedService._id
      );

      // console.log(index);
      // const response = await Get(
      //   PRODUCT.toggleStatus + "/" + selectedService._id,
      //   token,
      //   {}
      // );
      const newService = [...procucts];
      newService[index].status =
        newService[index].status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
      setModalOpen(false);
      setProducts(newService);
    } catch (error) {
      console.log(error.message);
    }
  };


  // console.log(paginationConfig,"paginationConfig")


  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <p>Previous</p>;
    }
    if (type === "next") {
      return <p>Next</p>;
    }
    return originalElement;
  };

  const columns = [

    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <span>{formatDateTime(item)}</span>,
    },
    
        {
      title: "Prize",
      dataIndex: "prize",
      key: "prize",
      // render: (text) => text.toUpperCase()
    },

    
    {
      title: "winners",
      dataIndex: "winners",
      key: "winners",
      render: (winners) => {
        return winners.length 
      },
    },
    {
      title: "entries",
      dataIndex: "entries",
      key: "entries",
      render: (entries) => {
    let sum =0
    entries.forEach((entry)=>{
        sum += entry.entryCount
    })

     return sum

      },
    },

    {
      title: "action",
      render: (docs) => (
        <FcApproval
          style={{ fontSize: "16px", color: "#C90000", cursor: "pointer" }}
          onClick={() => Navigate("/giveaway-details/" + docs._id  , {state : {winners :  docs.winners, entries : docs.entries }})}
        />
      ),
    },

  ];

  function handleDateChane(e){
    let res=  getDateRange(e)
    // console.log("check for null",res)
    setFilter({
      ...filter,
      startDate: res.startDate,
      endDate: res.endDate,
    });
  }



    const filteredUsers = useMemo(() => {
      return allUsers?.filter((user) => {
        const searchQuery = searchValue.toLowerCase();
        return (
          user.firstName.toLowerCase().startsWith(searchQuery) ||
          user.lastName.toLowerCase().startsWith(searchQuery) ||
          user.email.toLowerCase().startsWith(searchQuery)
        );
      });
    }, [allUsers, searchValue]);

  
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function searchUser(e) {
    const { value } = e.target;
    setSearchValue(value);
  }


  const filterContent = (
    <div className="filterDropdown">
      <div>
        <p className="mainLabel" style={{ padding: "10px" }}>
          Filter
        </p>
      </div>
      <hr style={{ margin: 0 }} />

      <div className="filterDropdownBody">
        <p className="mainLabel">Creation Date:</p>
        <DatePicker
          showTime
          format="YYYY-MM-DD HH:mm"
          value={filter?.startDate}
          onChange={(e) => handleFrom(e)}
          placeholder="Select Date and Time"
          style={{width : "100%" ,paddingTop : "10px" ,paddingBottom:"10px"}} 
          className="my-3"

        />
          <DatePicker
          showTime
          format="YYYY-MM-DD HH:mm"
          value={filter.endDate}
          onChange={(e) => handleTo(e)}
          placeholder="Select Date and Time"
          style={{width : "100%" ,paddingTop : "10px" ,paddingBottom:"10px"}} 
          className="my-3"
        />


        <Button
          type="primary"
          shape="round"
          block
          size={"large"}
          style={{ marginBottom: "10px" }}
          className="mainButton primaryButton"
          onClick={() => refetch()}
        >
          Apply
        </Button>
        <Button
          type="primary"
          shape="round"
          block
          size={"large"}
          className="mainButton primaryButton2"
          onClick={() => resetFilter()}
        >
          Clear All
        </Button>
      </div>
    </div>
  );

  return (
    <Layout className="configuration">
      <div className="boxDetails">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <h1 className="pageTitle">Giveaway Reports</h1>
          </Col>
          <Col
            xs={24}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >


          {data?.totalAmount &&
          <div className="flex items-center gap-5 text-2xl font-semibold text-black">
          <p>Total Amount </p>
          <p>$ {data?.totalAmount}</p>
          </div>
          }



          </Col>
        </Row>


        <Row style={{ padding: "10px 20px" }}>
          <Col xs={24} md={12}>

          <Row className="flex items-center gap-7 text-black">
          <div>
          <h5 style={{ display: "inline", fontSize: 16 }}>Show : </h5>
            <Select
              size={"large"}
              className="chartSelectBox"
              defaultValue={paginationConfig.limit}
              onChange={(e) => handleLimitChange(e)}
              style={{
                width: 70,
                textAlign: "left",
              }}
              options={[
                { value: 10, label: "10" },
                { value: 20, label: "20" },
                { value: 30, label: "30" },
                { value: 40, label: "40" },
                { value: 50, label: "50" },
              ]}
            />
            &emsp;
            <h5 style={{ display: "inline", fontSize: 16 }}>Entries</h5>
          </div>


          <div>
          <h5 style={{ display: "inline", fontSize: 16 }}>Select : </h5>
            <Select
              size={"large"}
              className="chartSelectBox"
              defaultValue={"Today"}
              onChange={(e) => handleDateChane(e)}
              style={{
                width: 120,
                textAlign: "left",
              }}
              options={[
                { value: "Today", label: "Today" },
                { value: "Yesterday", label: "Yesterday" },
                { value: "This Week", label: "This Week" },
                { value: "This Month", label: "This Month" },
                { value: "Last Month", label: "Last Month" },
                { value: "This Year", label: "This Year" },
              ]}
            />
            &emsp;
            <h5 style={{ display: "inline", fontSize: 16 }}>Date</h5>
          </div>


          
                <Button 
                className="text-xs"
                onClick={() => {
                  if(user){
                    setUser(null)
                  }else{
                    setIsModalOpen(true)
                  }
                }}
                >{user ? "Remove Selection" : "Select User"}</Button>

              {user && (
                     <div
                     className="flex items-center gap-2 lg:hover:bg-gray-200 rounded-md"
                     >
                     <img
                       className="rounded-full h-6 w-6"
                       src={user?.image ? UPLOADS_URL+user?.image :"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt0XikLERJ8A3kTEC6_j9lMiLFu7-27j_AyA&s"}
                       alt="user"
                     />
                     <div className="flex flex-col gap-0">
                       <p className="whitespace-nowrap text-xs">
                         {`${user?.firstName + " " + user?.lastName} ${(user?.role)}`}
                       </p>
                     </div>
                   </div>
              )}


            {/* Modal */}
            <div>
            <Modal
                      className="w-full"
                      title="Find User"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      footer={null}
                    >
                      <input
                        onChange={searchUser}
                        className="w-full outline-none border-b-[1px] text-xl bg-white font-semibold border-black"
                        placeholder="Type name to search"
                        type="text"
                        value={searchValue}
                      />
                      <div className="mt-4 overflow-y-auto h-[500px]">
                        {filteredUsers?.length > 0  ? (
                          filteredUsers.map((user, i) => (
                            <div
                              className="flex items-center gap-5  lg:hover:bg-gray-200 rounded-md w-full p-2"
                              onClick={() => {
                                setUser(user)
                                setIsModalOpen(false);
                              }}
                              key={i}
                            >
                              <img
                                className="rounded-full h-10 w-10"
                                src={user?.image ? UPLOADS_URL+user?.image :"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt0XikLERJ8A3kTEC6_j9lMiLFu7-27j_AyA&s"}
                                alt="user"
                              />
                              <div className="flex flex-col gap-0">
                                <h1 className="whitespace-nowrap">
                                  {`${user?.firstName + " " + user?.lastName} 
                                  ${user?.role !== "USER" ? `${user?.role}` : "" }
                                  `}
                                </h1>
                              </div>
                            </div>
                          ))
                        ) : (
                          <>
                           {searchValue &&  <p>No User Found!</p> }
                          </>
                        )}
                      </div>
            </Modal>
            </div>


          </Row>

         
          </Col>





          <Col
            xs={24}
            md={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Input
              style={{ width: "250px" }}
              className="mainInput dashInput"
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
              suffix={
                <FaSearch
                  style={{
                    color: "#3c5a92",
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    refetch()
                  }
                />
              }
              onPressEnter={(e) =>
                refetch()
              }
            />
            &emsp;
            <Popover
              content={filterContent}
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
              placement="bottomRight"
              arrow={false}
            >
              <Button
                style={{
                  padding: "10px 15px",
                  height: "auto",
                  // backgroundColor: "#3c5a92",
                }}
                className="fltr-btn"
              >
                <FaFilter style={{ fontSize: "16px", color: "white" }} />
              </Button>
            </Popover>
          </Col>
        </Row>

        <Row style={{ padding: 20, overflow: "auto" }}>
          {isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Skeleton active />
              <br />
            </div>
          ) : (
            <Table
              className="styledTable"
              dataSource={data?.docs}
              columns={columns}
              pagination={false}
            />
          )}
        </Row>
        <Row style={{ padding: "10px 20px" }}>
          <Col xs={24} md={12}>
            <p className="text-black">{message}</p>
          </Col>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Pagination
              className="styledPagination"
              onChange={(e) => handlePageChange(e)}
              current={parseInt(paginationConfig.pageNumber)}
              pageSize={paginationConfig.limit}
              total={paginationConfig.totalDocs}
              itemRender={itemRender}
            />
          </Col>
        </Row>
        <br />
      </div>
      <br />
      <br />
      <Modal
        visible={modalOpen}
        onOk={() => handleStatus()}
        onCancel={() => setModalOpen(false)}
        okText="Yes"
        className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        cancelText="No"
        cancelButtonProps={{
          style: {
            border: "2px solid #b78a39",
            color: "#b78a39",
            height: "auto",
            padding: "6px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            marginTop: "15px",
          },
        }}
        okButtonProps={{
          style: {
            backgroundColor: "#b78a39",
            color: "white",
            marginTop: "15px",
            height: "auto",
            padding: "5px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            border: "2px solid #b78a39",
          },
        }}
      >
        <Image
          src={ImageUrl("question.png")}
          preview={false}
          width={100}
          height={120}
        />
        <Typography.Title level={4} style={{ fontSize: "25px" }}>
          {selectedService?.status === "ACTIVE" ? "Deactivate" : "Activate"}
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
          Do You Want To{" "}
          {selectedService?.status === "ACTIVE" ? "Deactivate" : "Activate"} This
          Service?
        </Typography.Text>
      </Modal>
    </Layout>
  );
}

export default Reports;
