import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UserAuthCheck, { ROLES } from "../../components/AuthCheck/UserAuthCheck";
import ClientLayout from "../../components/ClientLayout";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import ApprovedCoinsReport from "../../views/approvedCoinsReport";
import BankPayouts from "../../views/bank-payouts";
import Chat from "../../views/chat";
import CoinRequests from "../../views/coin-requests";
import Dashboard from "../../views/dashboard";
import EmailRequests from "../../views/email-requests";
import CategoryAdd from "../../views/events/addNewEvent";
import CategoryEdit from "../../views/events/editEvent";
import CategoryManagement from "../../views/events/index";
import ShowCategory from "../../views/events/viewEvent";
import FeedbackManagement from "../../views/feedback-management";
import FeedbackDetails from "../../views/feedback-management/feedbackDetails";
import ForgotPassword from "../../views/forget-password-1";
import ForgotPassword2 from "../../views/forget-password-2";
import ForgotPassword3 from "../../views/forget-password-3";
import GiveawayReport from "../../views/giveaway-report";
import GiveawayDetails from "../../views/giveaway-report/giveawayDetails";
import GraphView from "../../views/graph-view";
import IncommingGoldCoins from "../../views/incommin-gold-coins";
import InventoryManagement from "../../views/inventory-management";
import Productadd from "../../views/inventory-management/addNewProduct";
import EditProduct from "../../views/inventory-management/editProduct";
import ViewProduct from "../../views/inventory-management/viewProduct";
import JazInventoryManagement from "../../views/jaz-inventory-management";
import JazProductadd from "../../views/jaz-inventory-management/addNewProduct";
import JazEditProduct from "../../views/jaz-inventory-management/editProduct";
import JazViewProduct from "../../views/jaz-inventory-management/viewProduct";
import JazOrderManagement from "../../views/jaz-order-management";
import JazOrderDetails from "../../views/jaz-order-management/orderDetail";
import Notifications from "../../views/notifications";
import OrderManagement from "../../views/order-management";
import OrderDetails from "../../views/order-management/orderDetail";
import PayOutReports from "../../views/payoutReports";
import PaypalCredentials from "../../views/paypalCredentials";
import EditCredentials from "../../views/paypalCredentials/editCredentials";
import PostManagement from "../../views/post-management";
import PostAdd from "../../views/post-management/addNewPost";
import EditPost from "../../views/post-management/editPost";
import ViewPost from "../../views/post-management/viewPost";
import Profile from "../../views/profile";
import ChangePass from "../../views/profile/changePass";
import PurchasedReports from "../../views/purchasedReport";
import Settings from "../../views/settings";
import Signin from "../../views/signin";
import Tip from "../../views/tip";
import TopUsers from "../../views/top-users";
import TransactionLogs from "../../views/transaction-logs";
import Unauthorized from "../../views/unauthorized/unauthorized";
import UserManagement from "../../views/user-management";
import AddCoins from "../../views/user-management/addCoins";
import UserDetails from "../../views/user-management/userDetails";
import VerifyOtp from "../../views/user-management/verifyOtp";
import { isTokenExpired } from "../functions";

const routesConfig = [
  { path: "/signin", element: <Signin /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/forgot-password-2", element: <ForgotPassword2 /> },
  { path: "/forgot-password-3", element: <ForgotPassword3 /> },
  { path: "/", element: <Dashboard />, roles: "all", title: "Dashboard", activeTab: "dashboard" },
  { path: "/user-management", element: <UserManagement />, roles: "all", title: "User Management" },
  { path: "/tip", element: <Tip />, roles: [ROLES.super_admin], title: "Tip" },
  { path: "/user-management/:id", element: <UserDetails />, roles: "all", title: "User Management" },
  { path: "/user-management/update-coins/:id", element: <AddCoins />, roles: "all", title: "Update Coins" },
  { path: "/feedback-management", element: <FeedbackManagement />, roles: "all", title: "User Management" },
  { path: "/feedback-management/:id", element: <FeedbackDetails />, roles: "all", title: "Feedback Details" },
  { path: "/payment-credentials", element: <PaypalCredentials />, roles: "all", title: "Paypal credentials" },
  { path: "/payment-credentials/edit-credentials", element: <EditCredentials />, roles: "all", title: "Edit Paypal Credentials" },
  { path: "/transaction-logs/:id", element: <TransactionLogs />, roles: "all", title: "Transaction Logs" },
  { path: "/category-management", element: <CategoryManagement />, roles: "all", title: "Category" },
  { path: "/category-management/:id", element: <ShowCategory />, roles: "all", title: "View Category" },
  { path: "/category-management/addCategory", element: <CategoryAdd />, roles: "all", title: "Category add" },
  { path: "/category-management/editCategory/:id", element: <CategoryEdit />, roles: "all", title: "edit category" },
  { path: "/inventory-management", element: <InventoryManagement />, roles: "all", title: "Inventory Management" },
  { path: "/inventory-management/:id", element: <ViewProduct />, roles: "all", title: "View Product" },
  { path: "/inventory-management/addNewProduct", element: <Productadd />, roles: "all", title: "Product add" },
  { path: "/inventory-management/editProduct/:id", element: <EditProduct />, roles: "all", title: "edit Product" },
  { path: "/jaz-inventory-management", element: <JazInventoryManagement />, roles: "all", title: "Inventory Management" },
  { path: "/jaz-inventory-management/:id", element: <JazViewProduct />, roles: "all", title: "View Product" },
  { path: "/jaz-inventory-management/addNewProduct", element: <JazProductadd />, roles: "all", title: "Product add" },
  { path: "/jaz-inventory-management/editProduct/:id", element: <JazEditProduct />, roles: "all", title: "edit Product" },
  { path: "/order-management", element: <OrderManagement />, roles: "all", title: "Order Management" },
  { path: "/order-management/:id", element: <OrderDetails />, roles: "all", title: "Order Detail" },
  { path: "/jaz-order-management", element: <JazOrderManagement />, roles: "all", title: "Order Management" },
  { path: "/jaz-order-management/:id", element: <JazOrderDetails />, roles: "all", title: "Order Detail" },
  { path: "/settings", element: <Settings />, roles: "all", title: "Setting" },
  { path: "/bank-payouts/", element: <BankPayouts />, roles: "all", title: "Bank Payouts" },
  { path: "/incomming-gold-coins/", element: <IncommingGoldCoins />, roles: "all", title: "Incoming Gold Coins" },
  { path: "/coin-requests/", element: <CoinRequests />, roles: "all", title: "Red Coin Requests" },
  { path: "/email-requests/", element: <EmailRequests />, roles: "all", title: "Email Change Requests" },
  { path: "/profile", element: <Profile />, roles: "all", title: "profile" },
  { path: "/profile/changePassword", element: <ChangePass />, roles: "all", title: "ChangePass" },
  { path: "/notifications", element: <Notifications />, roles: "all", title: "Notifications" },
  { path: "/post-management", element: <PostManagement />, roles: "all", title: "Post Management" },
  { path: "/post-management/:id", element: <ViewPost />, roles: "all", title: "View Post" },
  { path: "/post-management/addNewPost", element: <PostAdd />, roles: "all", title: "Post add" },
  { path: "/post-management/editPost/:id", element: <EditPost />, roles: "all", title: "Edit Post" },
  { path: "/chat", element: <Chat />, roles: "all", title: "Chat" },
  { path: "/payout-reports", element: <PayOutReports />, roles: "all", title: "Inventory Management" },
  { path: "/purchased-reports", element: <PurchasedReports />, roles: "all", title: "Inventory Management" },
  { path: "/coins-reports", element: <ApprovedCoinsReport />, roles: "all", title: "Inventory Management" },
  { path: "/graph-view", element: <GraphView />, roles: ["SUPER_ADMIN"], title: "Inventory Management" },
  { path: "/top-users", element: <TopUsers />, roles: ["SUPER_ADMIN"], title: "Inventory Management" },
  { path: "/giveaway-report", element: <GiveawayReport />, roles: ["SUPER_ADMIN"], title: "Inventory Management" },
  { path: "/giveaway-details/:id", element: <GiveawayDetails />, roles: ["SUPER_ADMIN"], title: "Inventory Management" },
  { path: "/verify-otp", element: <VerifyOtp />, roles: ["SUPER_ADMIN"], title: "Inventory Management" },
  { path: "/unauthorized", element: <Unauthorized /> }
];

const MyRouter = () => {
  const token = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTokenExpired(token)) {
        dispatch(removeUser());
        window.location.reload();
      }
    }, 60000); // Check every 1 minute

    return () => clearInterval(interval); // Cleanup interval
  }, [token, dispatch]);

  useEffect(() => {
    if (localStorage.getItem("userData") && localStorage.getItem("userToken")) {
      dispatch(
        addUser({
          user: JSON.parse(localStorage.getItem("userData")),
          token: localStorage.getItem("userToken"),
        })
      );
    }
  }, [dispatch]);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        {routesConfig?.map(({ path, element, roles, title, description, activeTab }) => (
          <Route
            key={path}
            path={path}
            element={
              roles ? (
                <UserAuthCheck roles={roles}>
                  <ClientLayout head={{ title, description }} activeTab={activeTab}>
                    {element}
                  </ClientLayout>
                </UserAuthCheck>
              ) : (
                element
              )
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default MyRouter;
