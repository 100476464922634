import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row
} from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useGetPaypalKeysQuery } from "../../api/paypal";
import { Put } from "../../config/api/put";
import { useGetStripeKeysQuery } from "../../api/stripe";
import { useGetVivamoKeysQuery } from "../../api/vivamo";

function EditCredentials() {
  const location = useLocation();
  const paymentMethod = location.state?.paymentMethod || "";
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { data: paypalKeys } = useGetPaypalKeysQuery();
  const { data: stripeKeys } = useGetStripeKeysQuery();
  const { data: vivamoKeys } = useGetVivamoKeysQuery();

  useEffect(() => {
    let keys = {}
    if (paymentMethod === "paypal") {
      keys = paypalKeys
    } else if (paymentMethod === "stripe") {
      keys = stripeKeys
    } else {
      keys = vivamoKeys
    }

    try {
      form.setFieldsValue({
        ...keys
      });
    } catch (error) {
      console.log(error);
    }
  }, [paypalKeys, stripeKeys, vivamoKeys, form, paymentMethod]);

  const onFinish = (values) => {
    setLoading(true);
    let data = {}

    if (paymentMethod === "paypal") {
      data.clientId = values.clientId
      data.clientSecret = values.clientSecret
    } else if (paymentMethod === "stripe") {
      data.publishableKey = values.publishableKey
      data.secretKey = values.secretKey
    } else {
      data.publicKey = values.publicKey
      data.secretKey = values.secretKey
    }

    Put(
      `/${paymentMethod}/keys`,
      token,
      data,
      null
    )
      .then((response) => {
        setLoading(false);
        if (response?.status) {
          swal("Success", "Credentials updated successfully", "success");
          navigate(-1);
        } else {
          swal("Oops!", response?.message || response?.message, "error");
        }
      })
      .catch(() => {
        setLoading(false);
        message.error("An error occurred while updating the credentials.");
      });
  };

  return (
    <Layout className="configuration">
      <div className="boxDetails">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                cursor: "pointer",
              }}
              className="text-black"
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              {paymentMethod ? `Edit ${paymentMethod} Credentials` : "No method provided"}
            </h1>
          </Col>
        </Row>
        <br />
        <Row style={{ padding: "20px" }}>
          <Col xs={24} md={16}>
            <Form
              layout="vertical"
              name="product-edit"
              onFinish={onFinish}
              form={form}
            >
              <Form.Item
                label="Client Id"
                name={paymentMethod === "paypal" ? "clientId" : paymentMethod === "stripe" ? "publishableKey" : "publicKey"}
                rules={[{ required: true, message: "Please input Client Id!" }]}
              >
                <Input
                  size="large"
                  placeholder="Enter Client Id"
                  style={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "10px 20px",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Client Secret"
                name={paymentMethod === "paypal" ? "clientSecret" : "secretKey"}
                rules={[
                  { required: true, message: "Please input client secret!" },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Client Secret"
                  style={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "10px 20px",
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  block
                >
                  Update Credentials
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default EditCredentials;
